var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row content-header"},[_c('div',{staticClass:"content-header-left mb-2 col-md-9 col-12"},[_c('div',{staticClass:"row breadcrumbs-top"},[_c('div',{staticClass:"col-12"},[(_vm.user_role == 'assistant')?_c('div',[_c('h2',{staticClass:"content-header-title float-left pr-1 mb-0"},[_vm._v(" "+_vm._s(_vm.role)+" ")])]):_c('div',[_c('h2',{staticClass:"content-header-title float-left pr-1 mb-0"},[_vm._v(" "+_vm._s(_vm.user_role.charAt(0).toUpperCase() + _vm.user_role.slice(1))+" ")])]),_vm._m(0)])])])]),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{attrs:{"method":"post"},on:{"submit":_vm.updateUser}},[_c('b-card',[_c('h3',{staticClass:"mb-2 top-header"},[_c('feather-icon',{attrs:{"icon":"ArrowLeftIcon","size":"24"},on:{"click":_vm.goBack}}),_vm._v(" Basic Information ")],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Name"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Name","rules":{
									required: true,
									regex: RegExp(/^[a-zA-Z\ ]*$/),
									max: 30,
								}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
								var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"name","type":"text","placeholder":"Enter Name"},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Status","label-for":" status"}},[_c('div',{staticClass:"d-flex mt-1"},[_c('b-form-radio',{staticClass:"mr-1",attrs:{"name":"status","value":"Active"},model:{value:(_vm.formData.status),callback:function ($$v) {_vm.$set(_vm.formData, "status", $$v)},expression:"formData.status"}},[_vm._v(" Active")]),_c('b-form-radio',{attrs:{"name":"status","value":"Inactive"},model:{value:(_vm.formData.status),callback:function ($$v) {_vm.$set(_vm.formData, "status", $$v)},expression:"formData.status"}},[_vm._v("InActive ")])],1)])],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Phone No"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Phone","rules":{
									required: true,
									integer: true,
									min: 10,
								}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
								var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"phone","name":"phone_no","placeholder":"Enter Phone No"},model:{value:(_vm.formData.phone_no),callback:function ($$v) {_vm.$set(_vm.formData, "phone_no", $$v)},expression:"formData.phone_no"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('h3',{staticClass:"mb-2 mt-2 top-header"},[_vm._v("Address Information")]),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Address","label-for":"Address"}},[_c('b-form-textarea',{attrs:{"type":"text","name":"address","placeholder":"","rows":"3"},model:{value:(_vm.formData.address),callback:function ($$v) {_vm.$set(_vm.formData, "address", $$v)},expression:"formData.address"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Country"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('div',{staticClass:"form-label-group"},[_c('validation-provider',{attrs:{"name":"Country Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
								var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.countryList,"label":"name","reduce":function (e) { return e.id; }},on:{"input":_vm.getState},model:{value:(_vm.formData.country),callback:function ($$v) {_vm.$set(_vm.formData, "country", $$v)},expression:"formData.country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" State"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('div',{staticClass:"form-label-group"},[_c('validation-provider',{attrs:{"name":"State Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
								var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.stateList,"label":"name","reduce":function (e) { return e.id; },"clearable":true},on:{"input":_vm.getCity},model:{value:(_vm.formData.state),callback:function ($$v) {_vm.$set(_vm.formData, "state", $$v)},expression:"formData.state"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" City"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('div',{staticClass:"form-label-group"},[_c('validation-provider',{attrs:{"name":"City Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
								var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.cityList,"reduce":function (e) { return e.id; },"label":"name"},model:{value:(_vm.formData.city),callback:function ($$v) {_vm.$set(_vm.formData, "city", $$v)},expression:"formData.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1)],1),_c('div',{staticClass:"mb-5"},[_c('b-button',{staticClass:"mr-1",attrs:{"type":"submit","variant":"primary","value":"Submit"}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"breadcrumb-wrapper"},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item active"},[_c('span',{attrs:{"aria-current":"location"}},[_vm._v(" Edit ")])])])])}]

export { render, staticRenderFns }